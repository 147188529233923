<template lang="pug">
div.gridSection
  div.sectionmenu
    div.secondlevel
      .submenuValoration
        router-link(:to="{name: 'HealthyPokeIndicatorsHome', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Indicadores]
        router-link(:to="{name: 'HealthyPokeCommentsHome', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Comentarios privados]
        router-link(:to="{name: 'HealthyPokeRankingHome', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Ranking]
        router-link(:to="{name: 'HealthyPokeSurveysHome', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Encuestas (QR)]
    router-view

</template>

<script>
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"

export default {
  props: ['paramCOD', 'type', 'site', 'typeRestaurant', 'after', 'before'],
  mixins: [commonMixins],
  created() {
    this.$store.commit(types.UPDATE_SECTION, 3)
  }
}
</script>
